import { Canvas } from '@react-three/fiber';
import { Center } from '@react-three/drei';
import Shirt from './Shirt';
import CameraRig from './CameraRig';

const CanvasModel = () => {
  return (
    <Canvas
      camera={{ position: [0, 0, 0], fov: 25 }}
      gl={{ preserveDrawingBuffer: true }}
      style={{
        width: '100%',            
        height: '100vh',          
        backgroundColor: '#fff',  
        transition: 'all 0.3s ease-in', 
      }}
    >
      <ambientLight intensity={0.5} />
      
      <CameraRig>
        <Center>
          <Shirt />
        </Center>
      </CameraRig>
    </Canvas>
  );
};

export default CanvasModel;
