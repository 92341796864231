import React from 'react';
import { useSnapshot } from 'valtio';

import state from '../Core/config/store';
import { getContrastingColor } from '../Core/config/helpers';

const CustomButton = ({ type, title, customStyles, handleClick }) => {
  const snap = useSnapshot(state);

  return (
    <button
      style={{
        padding: '0.375rem 0.5rem', // Equivalent to px-2 py-1.5
        borderRadius: '0.375rem',   // Equivalent to rounded-md
        flex: 1,
        ...(type === 'filled'
          ? {
              backgroundColor: snap.color,
              color: getContrastingColor(snap.color),
            }
          : type === 'outline' && {
              borderWidth: '1px',
              borderColor: snap.color,
              color: snap.color,
            }),
        ...customStyles, // Allow custom styles to override default styles
      }}
      onClick={handleClick}
    >
      {title}
    </button>
  );
};

export default CustomButton;
