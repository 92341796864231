import React from 'react';

const TextureLogoPicker = ({ texturesLogos, handleTextureLogoClick }) => {
  // Group textures by their type (filtering is done once here)
  const groupedTextures = texturesLogos.reduce((acc, textureLogo) => {
    acc[textureLogo.type] = acc[textureLogo.type] || [];
    acc[textureLogo.type].push(textureLogo);
    return acc;
  }, {});

  // Render images based on the passed array of images
  const renderImages = (images) => (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '8px',
    }}>
      {images.map((image) => (
        <div key={image.name} onClick={() => handleTextureLogoClick(image)} style={{ cursor: 'pointer' }}>
          <img src={image.image} alt={image.name} style={{
            borderRadius: '50%', // rounded-full
            width: '100%',
            height: 'auto',
          }} />
        </div>
      ))}
    </div>
  );

  return (
    <div style={{
      position: 'absolute',
      left: '100%',
      marginLeft: '12px', // ml-3
    }}>
      <h2 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Textures</h2>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'scroll',
        width: '160px',  // w-40 (40 * 4px = 160px)
        height: '160px', // h-40 (40 * 4px = 160px)
      }}>
        {renderImages(groupedTextures.texture || [])}
      </div>

      <h2 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Front Logos</h2>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'scroll',
        width: '160px',
        height: '160px',
      }}>
        {renderImages(groupedTextures.frontLogo || [])}
      </div>

      <h2 style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>Back Logos</h2>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'scroll',
        width: '160px',
        height: '160px',
      }}>
        {renderImages(groupedTextures.backLogo || [])}
      </div>
    </div>
  );
};

export default TextureLogoPicker;
