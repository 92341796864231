import React from 'react'
import Canvas from '../Pages/Components/canvas';
import Customizer from '../Pages/Components/pages/Customizer';

const ThreeD = () => {
  return (
    <div className="app transition-all ease-in">
      <Canvas />
      <Customizer />
    </div>
  )
}

export default ThreeD


