import React from 'react';
import CustomButton from './CustomButton';

const FilePicker = ({ file, setFile, readFile }) => {
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }} // Hide the file input to style with the label
        />
        <label
          htmlFor="file-upload"
          style={{
            backgroundColor: '#4CAF50', // Example color for the button
            color: 'white',
            padding: '10px 15px',
            borderRadius: '5px',
            cursor: 'pointer',
            textAlign: 'center',
          }}
        >
          Upload File
        </label>

        <p style={{ marginTop: '8px', color: '#6B7280', fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {file ? file.name : 'No file selected'}
        </p>
      </div>

      <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap', gap: '12px' }}>
        <CustomButton
          type="outline"
          title="Logo"
          handleClick={() => readFile('logo')}
          customStyles={{ fontSize: '12px' }}
        />
        <CustomButton
          type="filled"
          title="Full"
          handleClick={() => readFile('full')}
          customStyles={{ fontSize: '12px' }}
        />
      </div>
    </div>
  );
};

export default FilePicker;
