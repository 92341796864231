import React from "react";
import { AiOutlineDown } from "react-icons/ai";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Already imported
import { motion, AnimatePresence } from "framer-motion";
import { useSnapshot } from "valtio";
import state from "../Components/Core/config/store";
import CustomButton from "../Components/Core/CustomButton";
import { slideAnimation } from "../Components/Core/config/motion";

function Banner() {
  const navigate = useNavigate(); // Create navigate instance
  const snap = useSnapshot(state);
  const animation = slideAnimation("left");
  const animation1 = slideAnimation("down");

  return (
    <div>
      <div className="cms-section background-gradient-1 brands-emotion pos-0 cms-section-default">
        <div className="cms-section-default full-width">
          <div className="cms-block spized-banner-full-size white-text pos-0 cms-block-spized-banner">
            <div className="cms-block-container">
              <div className="cms-block-container-row row cms-row ">
                <div
                  className="col-12"
                  data-cms-element-id="018ba9504eec7933acd658e8875145b4"
                >
                  <div className="spized--banner-info-box">
                    <div className="image-box">
                      <img
                        src="https://www.spized.com/media/3f/62/36/1701076424/Header_01-INT.jpg"
                        className="img-fluid image-slider-image f_lazy desctop-image-view desctop-mobile-type"
                        width="50"
                        height="50"
                      />
                    </div>
                    <div className="inner-box">
                      <div className="spized--banner-slider-inner">
                        <div className="spized--banner-slider-description">
                          <h1 className="t1">
                            Customised sportswear for you and your team
                          </h1>
                          <p className="t2">
                            With our 3D configurator, you can easily design
                            professional-quality sportswear made just for you.
                            Why not kit out your club too with our attractive
                            discounts.
                          </p>
                          <p>
                            <a
                              className="btn btn-primary button2 customize-button"
                              onClick={() =>
                                (window.location.href = "/listing")
                              }
                              target="_blank"
                              style={{ backgroundColor: "#000000" }}
                            >
                              Design sportswear now
                            </a>
                          </p>
                          <p>
                            {/* <AnimatePresence>
                              {snap.intro && (
                                <motion.section
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100vh",
                                    backgroundColor: "#ffffff",
                                  }}
                                  initial={animation.initial}
                                  animate={animation.animate}
                                  exit={animation.exit}
                                >
                                  <motion.header
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginBottom: "20px",
                                    }}
                                    initial={animation1.initial}
                                    animate={animation1.animate}
                                    exit={animation1.exit}
                                  >
                                    <img
                                      src="./threejs.png"
                                      alt="logo"
                                      style={{
                                        width: "2rem",
                                        height: "2rem",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </motion.header>

                                  <motion.div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CustomButton
                                      type="filled"
                                      title="Open 3D configurator"
                                      handleClick={() => {
                                        window.location.href = '/3d-product';
                                      }}
                                      customStyles={{
                                        width: "fit-content",
                                        padding: "0.625rem 1rem",
                                        fontWeight: "bold",
                                        fontSize: "0.875rem",
                                      }}
                                      className="btn button2 button-secondary"
                                    />
                                  </motion.div>
                                </motion.section>
                              )}
                            </AnimatePresence> */}
                            <CustomButton
                              type="filled"
                              title="Open 3D configurator"
                              handleClick={() => {
                                window.location.href = "/3d-product";
                              }}
                              customStyles={{
                                width: "fit-content",
                                padding: "0.625rem 1rem",
                                fontWeight: "bold",
                                fontSize: "0.875rem",
                              }}
                              className="btn button2 button-secondary"
                            />
                          </p>
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              marginTop: "20px",
                              padding: "10px",
                              backgroundColor: "#007bff",
                              borderRadius: "50%",
                            }}
                          >
                            <AiOutlineDown
                              style={{
                                fontSize: "1.5rem",
                                color: "#000000",
                              }}
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
