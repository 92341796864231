import React from 'react';
import { useSnapshot } from 'valtio';
import state from '../Core/config/store';

const Tab = ({ tab, isFilterTab, isActiveTab, handleClick }) => {
  const snap = useSnapshot(state);

  const containerStyle = {
    backgroundColor: "transparent",
    opacity: 1,
    display: "flex",
    height: "3.5rem",
    width: "3.5rem",
    cursor: "pointer",
    userSelect: "none",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: isFilterTab ? '50%' : '4px',
    boxShadow: isActiveTab ? '0 4px 10px rgba(0, 0, 0, 0.2)' : 'none',
  };

  const imageStyle = {
    objectFit: "contain",
    width: isFilterTab ? "66.666667%" : "91.666667%", 
    height: isFilterTab ? "66.666667%" : "91.666667%",
    maxWidth: "100%",
    height: "auto",
    display: "block",
    verticalAlign: "middle",
  };

  return (
    <div style={containerStyle} onClick={handleClick}>
      <img
        src={tab.icon}
        alt={tab.name}
        style={imageStyle}
      />
    </div>
  );
};

export default Tab;
