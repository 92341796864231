import React, { useEffect, useState, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiMenu3Line } from "react-icons/ri";
import Sports from "../HeaderContent/Sports";
import AccountSec from "../HeaderContent/AccountSec";
import "../Css/fonts.css";
import { useNavigate } from "react-router-dom";
import { menuSection } from "../../Services/Operations/ProductAPI";
import { AiOutlineDown } from "react-icons/ai";
import "../../Styles/Header.css";

function Header() {
  const navigate = useNavigate();
  // const { tokenDetails } = useSelector((state) => state.user);
  const [menu, setMenu] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  // const token = tokenDetails;

  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

  const toggleDropdown = (index) => {
    setHoveredItemIndex(index);
  };

  const closeDropdown = () => {
    setHoveredItemIndex(null);
  };

  const list = [
    {
      cat_id: 1,
      title: "Badminton",
      subTitles: [
        { id: 1, sub: "Badminton Jersey" },
        { id: 2, sub: "Badminton Shorts" },
        { id: 3, sub: "Badminton Tank Top" },
      ],
    },
    {
      cat_id: 2,
      title: "Football",
      subTitles: [
        { id: 1, sub: "Football Jersey" },
        { id: 2, sub: "Football Shorts" },
        { id: 3, sub: "Football Shoes" },
      ],
    },
    {
      cat_id: 3,
      title: "Basketball",
      subTitles: [
        { id: 1, sub: "Basketball Jersey" },
        { id: 2, sub: "Basketball Shorts" },
        { id: 3, sub: "Basketball Shoes" },
      ],
    },
    {
      cat_id: 4,
      title: "Tennis",
      subTitles: [
        { id: 1, sub: "Tennis Racket" },
        { id: 2, sub: "Tennis Shorts" },
        { id: 3, sub: "Tennis Shoes" },
      ],
    },
    {
      cat_id: 5,
      title: "Running",
      subTitles: [
        { id: 1, sub: "Running Jersey" },
        { id: 2, sub: "Running Shorts" },
        { id: 3, sub: "Running Shoes" },
      ],
    },
  ];
  const chunkedList = [];
  for (let i = 0; i < list.length; i += 3) {
    chunkedList.push(list.slice(i, i + 3));
  }

  const isMounted = useRef(true);
  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await menuSection();
        if (isMounted.current) {
          setMenu(result.data);
        }
      } catch (error) {
        console.error("Error fetching menu:", error);
      }
    };

    loadData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div>
      <header className="header-main">
        <div className="block-container-2 header-block">
          <div
            className={`align-items-center header-row ${
              isMobileMenuOpen ? "mobile-align-items-center" : ""
            }`}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Mobile Menu Button */}
              <div className="d-block d-lg-none">
                <div className="menu-button f_menuActionContainer">
                  <button
                    className={`${
                      isMobileMenuOpen
                        ? "mobile-icon-btn"
                        : "btn nav-main-toggle-btn header-actions-btn"
                    }`}
                    onClick={toggleMobileMenu}
                    aria-label="Menu"
                  >
                    {isMobileMenuOpen ? (
                      <AiOutlineClose
                        className="icon-close"
                        style={{ color: "black" }}
                      />
                    ) : (
                      <RiMenu3Line
                        className="icon-burger-menu"
                        style={{ color: "black" }}
                      />
                    )}
                  </button>
                </div>
              </div>

              {/* Logo Section */}
              <div
                className="header-logo-col"
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  width: "max-content",
                }}
              >
                <div className="header-logo-main">
                  <a
                    className={`header-logo-main-link ${
                      isMobileMenuOpen ? "mobile-header-logo-main-link" : ""
                    }`}
                    onClick={() => navigate("/")}
                    title="Go to homepage"
                    style={{
                      fontSize: "24px",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Shield Sports
                  </a>
                </div>
              </div>
            </div>

            {/* Navigation Menu */}
            <div
              className={`${
                isMobileMenuOpen
                  ? "show-mobile-menu header-nav-box "
                  : "header-nav-box "
              }`}
            >
              <div className="main-navigation d-lg-block" id="mainNavigation">
                <div className="container">
                  <nav
                    className="nav main-navigation-menu default"
                    itemScope="itemscope"
                    itemType="http://schema.org/SiteNavigationElement"
                  >
                    <div className="main-navigation-menu-container">
                      {/* <Sports isMobileMenuOpen={isMobileMenuOpen} /> */}

                      <div style={{ width: "100%" }}>
                        <div
                          className="nav-link main-navigation-link"
                          title="Sports"
                          style={{ width: "100%", padding: "0px" }}
                        >
                          <div
                            className={`${
                              isMobileMenuOpen
                                ? "main-navigation-link-text mobile-main-navigation-link-text"
                                : "main-navigation-link-text"
                            }`}
                          >
                            {menu.map((item1, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onMouseEnter={() => {
                                  // Only toggle dropdown if the name is not "Design your own"
                                  if (item1.name !== "Design your own")
                                    toggleDropdown(index);
                                }}
                                onMouseLeave={() => {
                                  // Close dropdown only if the name is not "Design your own"
                                  if (item1.name !== "Design your own")
                                    closeDropdown();
                                }}
                                onClick={() => {
                                  // Navigate directly for "Design your own"
                                  if (item1.name === "Design your own") {
                                    window.location.href = "/3d-product";
                                  }
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "sans-serif",
                                    color: "black",
                                    fontWeight: "bold",
                                    width: "max-content",
                                  }}
                                >
                                  {item1.name}
                                </span>
                                {item1.name !== "Design your own" && (
                                  <AiOutlineDown
                                    className={
                                      hoveredItemIndex === index
                                        ? "icon-arrow-down-black"
                                        : "icon-arrow-up-black"
                                    }
                                    color="black"
                                  />
                                )}
                                {hoveredItemIndex === index &&
                                  item1.name !== "Design your own" && (
                                    <div
                                      className="dropdown-container"
                                      style={{
                                        position: "absolute",
                                        top: "33px",
                                        left: 0,
                                        width: "100vw",
                                        zIndex: 1000,
                                        backgroundColor: "white",
                                        boxShadow:
                                          "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "18px 0px 0px 28px",
                                      }}
                                    >
                                      <div
                                        style={{ display: "flex", flexGrow: 1 }}
                                      >
                                        {chunkedList.map(
                                          (chunk, columnIndex) => (
                                            <div
                                              key={columnIndex}
                                              className="navigation-flyout-col"
                                              style={{
                                                flexBasis: "33%",
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "5px",
                                              }}
                                            >
                                              {chunk.map((item) => (
                                                <div
                                                  key={item.cat_id}
                                                  style={{
                                                    paddingBottom: "10px",
                                                  }}
                                                >
                                                  <div title={item.title}>
                                                    <span
                                                      style={{
                                                        fontFamily:
                                                          "sans-serif",
                                                        color: "black",
                                                        fontWeight: "bold",
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      {item.title}
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    {item.subTitles?.map(
                                                      (subItem) => (
                                                        <a
                                                          key={subItem.id}
                                                          onClick={() => {
                                                            if (
                                                              item1.name ===
                                                              "Design your own"
                                                            ) {
                                                              window.location.href =
                                                                "/3d-product";
                                                            } else {
                                                              window.location.href =
                                                                "/productListing";
                                                            }
                                                          }}
                                                          style={{
                                                            fontFamily:
                                                              "sans-serif",
                                                            color: "black",
                                                            textDecoration:
                                                              "none",
                                                            marginBottom: "5px",
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          {subItem.sub}
                                                        </a>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                        )}
                                      </div>

                                      <div
                                        className="navigation-flyout-col highlights"
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                          padding: "10px",
                                          height: "100%",
                                        }}
                                      >
                                        <img
                                          src="https://www.spized.com/thumbnail/26/73/a7/1702032557/navigations-popup_socken-neu_1920x1920.jpg"
                                          className="product-image"
                                          width="300"
                                          height="300"
                                          alt="Product"
                                          style={{
                                            marginBottom: "10px",
                                            flexShrink: 0,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            {/* Account and Cart Section */}
            <div
              className={`header-action-box ${
                isMobileMenuOpen
                  ? "header-action-box mobile-header-action-box"
                  : ""
              }`}
            >
              <AccountSec />
              <div
                className="header-cart-btn is-opened"
                data-cart-widget="true"
                onClick={() => (window.location.href = "/cart")}
                style={{
                  cursor: "pointer",
                  marginLeft: "30px",
                  width: "25px",
                  height: "29px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.854"
                  height="26.335"
                  viewBox="0 0 25.854 26.335"
                  style={{ width: "16px" }}
                >
                  <g transform="translate(-3.133 -4.166)">
                    <path
                      d="M26.25,113.343a1.968,1.968,0,1,0,1.968-1.968A1.968,1.968,0,0,0,26.25,113.343Z"
                      transform="translate(-17.94 -85.81)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M70,113.343a1.968,1.968,0,1,0,1.968-1.968A1.968,1.968,0,0,0,70,113.343Z"
                      transform="translate(-53.819 -85.81)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M4.375,12.974,6.819,21.25a1.574,1.574,0,0,0,1.522,1.168H19.693a1.574,1.574,0,0,0,1.525-1.168L24.616,8.5A2.36,2.36,0,0,1,26.9,6.743h1.089"
                      transform="translate(0)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M10,5v9.286M5,10l5,5,5-5"
                      transform="translate(4.375 0.166)"
                      fill="none"
                      stroke="#1a73e9"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;

// import React, { useEffect, useState, useRef } from "react";
// import { AiOutlineClose } from "react-icons/ai";
// import { RiMenu3Line } from "react-icons/ri";
// import Sports from "../HeaderContent/Sports";
// import AccountSec from "../HeaderContent/AccountSec";
// import "../Css/fonts.css";
// import { useNavigate } from "react-router-dom";
// import { menuSection } from "../../Services/Operations/ProductAPI";
// import { AiOutlineDown } from "react-icons/ai";
// import "../../Styles/Header.css"; // Import custom CSS

// function Header() {
//   const navigate = useNavigate();
//   const [menu, setMenu] = useState([]);
//   const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//   const toggleDropdown = (index) => {
//     setHoveredItemIndex(index);
//   };

//   const closeDropdown = () => {
//     setHoveredItemIndex(null);
//   };

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };

//   const list = [
//     {
//       cat_id: 1,
//       title: "Badminton",
//       subTitles: [
//         { id: 1, sub: "Badminton Jersey" },
//         { id: 2, sub: "Badminton Shorts" },
//         { id: 3, sub: "Badminton Tank Top" },
//       ],
//     },
//     {
//       cat_id: 2,
//       title: "Football",
//       subTitles: [
//         { id: 1, sub: "Football Jersey" },
//         { id: 2, sub: "Football Shorts" },
//         { id: 3, sub: "Football Shoes" },
//       ],
//     },
//     {
//       cat_id: 3,
//       title: "Basketball",
//       subTitles: [
//         { id: 1, sub: "Basketball Jersey" },
//         { id: 2, sub: "Basketball Shorts" },
//         { id: 3, sub: "Basketball Shoes" },
//       ],
//     },
//     {
//       cat_id: 4,
//       title: "Tennis",
//       subTitles: [
//         { id: 1, sub: "Tennis Racket" },
//         { id: 2, sub: "Tennis Shorts" },
//         { id: 3, sub: "Tennis Shoes" },
//       ],
//     },
//     {
//       cat_id: 5,
//       title: "Running",
//       subTitles: [
//         { id: 1, sub: "Running Jersey" },
//         { id: 2, sub: "Running Shorts" },
//         { id: 3, sub: "Running Shoes" },
//       ],
//     },
//   ];

//   const chunkedList = [];
//   for (let i = 0; i < list.length; i += 3) {
//     chunkedList.push(list.slice(i, i + 3));
//   }

//   const isMounted = useRef(true);
//   useEffect(() => {
//     const loadData = async () => {
//       try {
//         const result = await menuSection();
//         if (isMounted.current) {
//           setMenu(result.data);
//         }
//       } catch (error) {
//         console.error("Error fetching menu:", error);
//       }
//     };

//     loadData();
//     return () => {
//       isMounted.current = false;
//     };
//   }, []);

//   return (
//     <div>
//       <header className="header-main">
//         <div className="block-container-2 header-block">
//           <div className="align-items-center header-row">
//             <div className="d-block d-lg-none">
//               <div className="menu-button f_menuActionContainer">
//                 <button
//                   className="btn nav-main-toggle-btn header-actions-btn"
//                   onClick={toggleMobileMenu}
//                   aria-label="Menu"
//                 >
//                   {isMobileMenuOpen ? (
//                     <AiOutlineClose className="icon-close" />
//                   ) : (
//                     <RiMenu3Line className="icon-burger-menu" />
//                   )}
//                 </button>
//               </div>
//             </div>

//             <div className="header-logo-col">
//               <div className="header-logo-main">
//                 <a
//                   className="header-logo-main-link"
//                   href
//                   onClick={() => navigate("/")}
//                   title="Go to homepage"
//                 >
//                   Shield Sports
//                 </a>
//               </div>
//             </div>

//             <div
//               className={`header-nav-box ${
//                 isMobileMenuOpen ? "show-mobile-menu" : ""
//               }`}
//             >
//               <nav className="main-navigation" id="mainNavigation">
//                 {menu.map((item, index) => (
//                   <div
//                     key={index}
//                     className="menu-item"
//                     onMouseEnter={() => toggleDropdown(index)}
//                     onMouseLeave={closeDropdown}
//                   >
//                     <span>{item.name}</span>
//                     <AiOutlineDown
//                       className={
//                         hoveredItemIndex === index
//                           ? "icon-arrow-down-black"
//                           : "icon-arrow-up-black"
//                       }
//                     />
//                     {hoveredItemIndex === index && (
//                       <div className="dropdown-container">
//                         <div className="dropdown-content">
//                           {chunkedList.map((chunk, columnIndex) => (
//                             <div key={columnIndex} className="menu-column">
//                               {chunk.map((subItem) => (
//                                 <div key={subItem.cat_id}>
//                                   <div title={subItem.title}>
//                                     <span>{subItem.title}</span>
//                                   </div>
//                                   <div>
//                                     {subItem.subTitles?.map((title) => (
//                                       <a
//                                         key={title.id}
//                                         href="/productListing"
//                                         onClick={(e) => {
//                                           e.preventDefault();
//                                           navigate("/productListing");
//                                         }}
//                                       >
//                                         {title.sub}
//                                       </a>
//                                     ))}
//                                   </div>
//                                 </div>
//                               ))}
//                             </div>
//                           ))}
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 ))}
//               </nav>
//             </div>

//             <div className="header-action-box">
//               <AccountSec />
//               <div
//                 className="header-cart-btn is-opened"
//                 onClick={() => (window.location.href = "/cart")}
//               >
//                 <svg /* Cart icon SVG */ />
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>
//     </div>
//   );
// }

// export default Header;
