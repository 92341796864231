// // import React, { useState } from "react";
// // import { useSnapshot } from "valtio";

// // import LogoControls from "../canvas/LogoControls";
// // import TextControls from "../canvas/TextControls";
// // import state from "../Core/config/store";
// // import { downloadCanvasToImage, reader } from "../Core/config/helpers";
// // import {
// //   EditorTabs,
// //   FilterTabs,
// //   DecalTypes,
// //   texturesLogos,
// // } from "../Core/config/constants";
// // import {
// //   ColorPicker,
// //   CustomButton,
// //   FilePicker,
// //   TextureLogoPicker,
// //   Tab,
// // } from "../Picker";

// // const Customizer = () => {
// //   const snap = useSnapshot(state);

// //   const [file, setFile] = useState("");
// //   const [activeEditorTab, setActiveEditorTab] = useState("");
// //   const [activeFilterTab, setActiveFilterTab] = useState({
// //     frontLogoShirt: true,
// //     backLogoShirt: true,
// //     frontTextShirt: true,
// //     backTextShirt: true,
// //     stylishShirt: false,
// //   });

// //   // Handle content rendering based on the active tab
// //   const generateTabContent = () => {
// //     switch (activeEditorTab) {
// //       case "colorpicker":
// //         return <ColorPicker />;
// //       case "filepicker":
// //         return <FilePicker file={file} setFile={setFile} readFile={readFile} />;
// //       case "logocontrols":
// //         return <LogoControls />;
// //       case "textcontrols":
// //         return <TextControls />;
// //       case "texturelogopicker":
// //         return (
// //           <TextureLogoPicker
// //             texturesLogos={texturesLogos}
// //             handleTextureLogoClick={handleTextureLogoClick}
// //           />
// //         );
// //       default:
// //         return null;
// //     }
// //   };

// //   const handleTextureLogoClick = (textureLogo) => {
// //     if (textureLogo.type === "texture") {
// //       state.fullDecal = textureLogo.image;
// //     } else if (textureLogo.type === "frontLogo") {
// //       state.frontLogoDecal = textureLogo.image;
// //     } else if (textureLogo.type === "backLogo") {
// //       state.backLogoDecal = textureLogo.image;
// //     }
// //   };

// //   const handleDecals = (type, result) => {
// //     const decalType = DecalTypes[type];
// //     state[decalType.stateProperty] = result;

// //     if (!activeFilterTab[decalType.filterTab]) {
// //       handleActiveFilterTab(decalType.filterTab);
// //     }
// //   };

// //   const handleActiveFilterTab = (tabName) => {
// //     setActiveFilterTab((prevState) => {
// //       const newState = { ...prevState, [tabName]: !prevState[tabName] };

// //       switch (tabName) {
// //         case "frontLogoShirt":
// //           state.isFrontLogoTexture = !newState[tabName];
// //           break;
// //         case "backLogoShirt":
// //           state.isBackLogoTexture = !newState[tabName];
// //           break;
// //         case "frontTextShirt":
// //           state.isFrontText = !newState[tabName];
// //           break;
// //         case "backTextShirt":
// //           state.isBackText = !newState[tabName];
// //           break;
// //         case "stylishShirt":
// //           state.isFullTexture = !newState[tabName];
// //           break;
// //         case "downloadShirt":
// //           downloadCanvasToImage();
// //           break;
// //         default:
// //           state.isFrontLogoTexture = true;
// //           state.isBackLogoTexture = true;
// //           state.isFrontText = true;
// //           state.isBackText = true;
// //           state.isFullTexture = false;
// //           break;
// //       }

// //       return newState;
// //     });
// //   };

// //   const readFile = (type) => {
// //     reader(file).then((result) => {
// //       handleDecals(type, result);
// //       setActiveEditorTab("");
// //     });
// //   };

// //   return (
// //     <div>
// //       {!snap.intro && (
// //         <>
// //           <div
// //             key="custom"
// //             style={{
// //               position: "absolute",
// //               top: 0,
// //               left: 0,
// //               zIndex: 10,
// //             }}
// //           >
// //             <div
// //               style={{
// //                 display: "flex",
// //                 alignItems: "center",
// //                 minHeight: "100vh",
// //               }}
// //             >
// //               <div
// //                 style={{ display: "flex", flexDirection: "column", flex: 1 }}
// //               >
// //                 {EditorTabs.map((tab) => (
// //                   <Tab
// //                     key={tab.name}
// //                     tab={tab}
// //                     handleClick={() => setActiveEditorTab(tab.name)}
// //                   />
// //                 ))}

// //                 {generateTabContent()}
// //               </div>
// //             </div>
// //           </div>

// //           <div
// //             style={{
// //               position: "absolute",
// //               zIndex: 10,
// //               top: "1.25rem",
// //               right: "1.25rem",
// //             }}
// //           >
// //             <CustomButton
// //               type="filled"
// //               title="Go Back"
// //               handleClick={() => (state.intro = true)}
// //               customStyles={{
// //                 width: "fit-content",
// //                 padding: "0.625rem 1rem",
// //                 fontWeight: "bold",
// //                 fontSize: "0.875rem",
// //               }}
// //             />
// //           </div>

// //           <div
// //             style={{
// //               position: "absolute",
// //               bottom: 0,
// //               left: 0,
// //               right: 0,
// //               zIndex: 10,
// //             }}
// //           >
// //             {FilterTabs.map((tab) => (
// //               <Tab
// //                 key={tab.name}
// //                 tab={tab}
// //                 isFilterTab
// //                 isActiveTab={activeFilterTab[tab.name]}
// //                 handleClick={() => handleActiveFilterTab(tab.name)}
// //               />
// //             ))}
// //           </div>
// //         </>
// //       )}
// //     </div>
// //   );
// // };

// // export default Customizer;

import React, { useState } from "react";
import { useSnapshot } from "valtio";

import LogoControls from "../canvas/LogoControls";
import TextControls from "../canvas/TextControls";
import state from "../Core/config/store";
import { downloadCanvasToImage, reader } from "../Core/config/helpers";
import {
  EditorTabs,
  FilterTabs,
  DecalTypes,
  texturesLogos,
} from "../Core/config/constants";
import {
  ColorPicker,
  CustomButton,
  FilePicker,
  TextureLogoPicker,
  Tab,
} from "../Picker";

const Customizer = () => {
  const snap = useSnapshot(state);

  const [file, setFile] = useState('');

  const [activeEditorTab, setActiveEditorTab] = useState("");
  const [activeFilterTab, setActiveFilterTab] = useState({
    frontLogoShirt: true,
    backLogoShirt: true,
    frontTextShirt: true,
    backTextShirt: true,
    stylishShirt: false,
  })

  // show tab content depending on the activeTab
  const generateTabContent = () => {
    switch (activeEditorTab) {
      case "colorpicker":
        return <ColorPicker />
      case "filepicker":
        return <FilePicker
          file={file}
          setFile={setFile}
          readFile={readFile}
        />
      case "logocontrols":
        return <LogoControls />;
      case "textcontrols":
        return <TextControls />;
      case "texturelogopicker":
        return (
          <TextureLogoPicker
            texturesLogos={texturesLogos}
            handleTextureLogoClick={handleTextureLogoClick}
          />
        );
      default:
        return null;
    }
  }

  const handleTextureLogoClick = (textureLogo) => {
    if (textureLogo.type === "texture") {
      state.fullDecal = textureLogo.image;
    } else if (textureLogo.type === "frontLogo") {
      state.frontLogoDecal = textureLogo.image;
    } else if (textureLogo.type === "backLogo") {
      state.backLogoDecal = textureLogo.image
    }
  };
  

  const handleDecals = (type, result) => {
    const decalType = DecalTypes[type];

    state[decalType.stateProperty] = result;

    if(!activeFilterTab[decalType.filterTab]) {
      handleActiveFilterTab(decalType.filterTab)
    }
  }

  const handleActiveFilterTab = (tabName) => {
    switch (tabName) {
      case "frontLogoShirt":
          state.isFrontLogoTexture = !activeFilterTab[tabName];
        break;
      case "backLogoShirt":
          state.isBackLogoTexture = !activeFilterTab[tabName];
        break;
      case "frontTextShirt":
          state.isFrontText = !activeFilterTab[tabName];
        break;
      case "backTextShirt":
          state.isBackText = !activeFilterTab[tabName];
        break;
      case "stylishShirt":
          state.isFullTexture = !activeFilterTab[tabName];
        break;
        case "downloadShirt":
          downloadCanvasToImage();
        break;
      default:
        state.isFrontLogoTexture = true;
        state.isBackLogoTexture = true;
        state.isFrontText = true;
        state.isBackText = true;
        state.isFullTexture = false;
        break;
    }

    setActiveFilterTab((prevState) => {
      return {
        ...prevState,
        [tabName]: !prevState[tabName]
      }
    })
  }

  const readFile = (type) => {
    reader(file)
      .then((result) => {
        handleDecals(type, result);
        setActiveEditorTab("");
      })
  }

  return (
    <div>
      <>
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            zIndex: 10,
            display: "flex",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1 0%",
            }}
          >
            {EditorTabs.map((tab) => (
              <Tab
                key={tab.name}
                tab={tab}
                handleClick={() => setActiveEditorTab(tab.name)}
              />
            ))}
            {generateTabContent()}
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: "1.25rem",
            right: "1.25rem",
            zIndex: 10,
          }}
        >
          <CustomButton
            type="filled"
            title="Go Back"
            handleClick={() => (state.intro = true)}
            customStyles={{
              width: "fit-content",
              padding: "0.625rem 1rem",
              fontWeight: "bold",
              fontSize: "0.875rem",
            }}
          />
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            right: "0px",
            display: "flex",
            justifyContent: "center",
            zIndex: 10,
          }}
        >
          {FilterTabs.map((tab) => (
            <Tab
              key={tab.name}
              tab={tab}
              isFilterTab
              isActiveTab={activeFilterTab[tab.name]}
              handleClick={() => handleActiveFilterTab(tab.name)}
            />
          ))}
        </div>
      </>
    </div>
  );
};

export default Customizer;
