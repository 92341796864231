import React from 'react';
import { useSnapshot } from 'valtio';
import state from '../Core/config/store';

const LogoControls = () => {
  const snap = useSnapshot(state);

  const handlePositionChange = (type, index, value) => {
    if (type === 'front') {
      state.frontLogoPosition[index] = value;
    } else if (type === 'back') {
      state.backLogoPosition[index] = value;
    }
  };

  const handleScaleChange = (type, value) => {
    if (type === 'front') {
      state.frontLogoScale = value;
    } else if (type === 'back') {
      state.backLogoScale = value;
    }
  };

  return (
    <div style={{ position: 'absolute', left: '100%', marginLeft: '12px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#4B5563' }}>FX:</span>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('front', 0, snap.frontLogoPosition[0] - 0.01)}
          >
            -
          </button>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('front', 0, snap.frontLogoPosition[0] + 0.01)}
          >
            +
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#4B5563' }}>FY:</span>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('front', 1, snap.frontLogoPosition[1] - 0.01)}
          >
            -
          </button>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('front', 1, snap.frontLogoPosition[1] + 0.01)}
          >
            +
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#4B5563' }}>FZ:</span>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('front', 2, snap.frontLogoPosition[2] - 0.01)}
          >
            -
          </button>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('front', 2, snap.frontLogoPosition[2] + 0.01)}
          >
            +
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#4B5563' }}>FS:</span>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handleScaleChange('front', snap.frontLogoScale - 0.01)}
          >
            -
          </button>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handleScaleChange('front', snap.frontLogoScale + 0.01)}
          >
            +
          </button>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#4B5563' }}>BX:</span>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('back', 0, snap.backLogoPosition[0] - 0.01)}
          >
            -
          </button>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('back', 0, snap.backLogoPosition[0] + 0.01)}
          >
            +
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#4B5563' }}>BY:</span>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('back', 1, snap.backLogoPosition[1] - 0.01)}
          >
            -
          </button>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('back', 1, snap.backLogoPosition[1] + 0.01)}
          >
            +
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#4B5563' }}>BZ:</span>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('back', 2, snap.backLogoPosition[2] - 0.01)}
          >
            -
          </button>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handlePositionChange('back', 2, snap.backLogoPosition[2] + 0.01)}
          >
            +
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span style={{ color: '#4B5563' }}>BS:</span>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handleScaleChange('back', snap.backLogoScale - 0.01)}
          >
            -
          </button>
          <button
            style={{
              border: '1px solid #D1D5DB',
              borderRadius: '6px',
              padding: '8px',
            }}
            onClick={() => handleScaleChange('back', snap.backLogoScale + 0.01)}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoControls;
