import React from 'react';
import { SketchPicker } from 'react-color';
import { useSnapshot } from 'valtio';

import state from '../Core/config/store';

const ColorPicker = () => {
  const snap = useSnapshot(state);

  const handleColorChange = (color) => {
    // Update the color in the global state
    state.color = color.hex;
  };

  return (
    <div style={{
      position: 'absolute',
      left: '100%',
      marginLeft: '0.75rem', // Equivalent to ml-3 (3 * 0.25rem)
    }}>
      <SketchPicker
        color={snap.color}
        disableAlpha
        onChange={handleColorChange}  // Updated for better separation of concerns
      />
    </div>
  );
};

export default ColorPicker;
